import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FiChevronDown, FiEdit } from "react-icons/fi";
import {
  Button,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
} from "@chakra-ui/react";

const MarktersAccountsTable = ({
  data,
  page,
  setShowUpdateAccount,
  setAccount,
  setSort,
  sort,
}) => {
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();

  return (
    <Box overflowX="auto">
      <Table variant="striped" colorScheme="orange">
        <Thead bg="gray.100">
          <Tr>
            <Th>#</Th>

            <Th cursor="pointer" onClick={() => setSort("")}>
              {t("pages.accounts.marketer")}
              {sort === "" && <FiChevronDown />}
            </Th>
            <Th>الكود</Th>
            <Th>رقم الهاتف</Th>
            <Th cursor="pointer" onClick={() => setSort("pending")}>
              {t("pages.accounts.pending")}
              {sort === "pending" && <FiChevronDown />}
            </Th>
            <Th cursor="pointer" onClick={() => setSort("preparing")}>
              {t("pages.accounts.preparing")}
              {sort === "preparing" && <FiChevronDown />}
            </Th>
            <Th cursor="pointer" onClick={() => setSort("shipped")}>
              {t("pages.accounts.shipped")}
              {sort === "shipped" && <FiChevronDown />}
            </Th>
            <Th cursor="pointer" onClick={() => setSort("available")}>
              {t("pages.accounts.available")}
              {sort === "available" && <FiChevronDown />}
            </Th>
            {auth.user?.rule?.name === "superadmin" && <Th>تعديل الأرصدة</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((el, index) => (
            <Tr
              key={el._id}
              _hover={{ bg: "gray.50" }}
              className="transition duration-300"
            >
              <Td>{(page - 1) * 10 + (index + 1)}</Td>
              <Td>{el.marketer?.name || "-"}</Td>
              <Td>{el.marketer?.code || "-"}</Td>
              <Td>{el.marketer?.phone || "-"}</Td>
              <Td className="text-yellow-500">{el.pending || "0"}</Td>
              <Td className="text-blue-500">{el.preparing || "0"}</Td>
              <Td className="text-red-500">{el.shipped || "0"}</Td>
              <Td className="text-green-500">{el.available || "0"}</Td>
              {auth.user?.rule?.name === "superadmin" && (
                <Td>
                  <Button
                    bg="transparent"
                    color="red.500"
                    size="sm"
                    _hover={{ bg: "red.100" }}
                    onClick={() => {
                      setShowUpdateAccount(true);
                      setAccount(el);
                    }}
                    className="transition duration-300"
                  >
                    <FiEdit size={18} />
                  </Button>
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default MarktersAccountsTable;
