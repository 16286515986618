import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Box,
  Divider,
  Image,
  Link,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useToast,
  Flex,
  useDisclosure,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tabs,
  CircularProgressLabel,
  CircularProgress,
} from "@chakra-ui/react";
import { FaMoneyBillTrendUp } from "react-icons/fa6";

import Breadcrumbs from "../../../shared/breadcrumbs/Breadcrumbs";
import { addToCart } from "../../../../store/cart/cartSlice";
import { getProductById } from "../../../../store/products/productsSlice";

import { FaCheckCircle, FaChartLine } from "react-icons/fa"; // Import icons

import theme from "../../../global/theme";
import CartDrawer from "../../cart/CartDrawer";

const ProductDetails = () => {
  const { t } = useTranslation();
  const toast = useToast({
    status: "success",
    position: "top",
    duration: 800,
  });
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const products = useSelector((state) => state.products);
  const [selectedList, setSelectedList] = useState([]);
  const cart = useSelector((state) => state.cart);
  const { productId } = useParams();
  const [view, setView] = useState("description");
  const {
    isOpen: isCartOpen,
    onOpen: onCartOpen,
    onClose: onCartClose,
  } = useDisclosure();

  useEffect(() => {
    dispatch(getProductById({ productId }));
  }, [dispatch, productId]);

  const product = products.product;

  return (
    <div>
      <Breadcrumbs
        currentPage={t("pages.products.products")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {!product ? (
        <Text>please wait ....</Text>
      ) : (
        <>
          <Divider mt={6} borderColor={theme.gary1} />
          <div className="py-8 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
              <div className="flex flex-col -mx-4 md:flex-row">
                <div className="px-4 md:flex-1">
                  <div className="h-[460px] rounded-lg bg-gray-300 dark:bg-gray-700 mb-4">
                    <img
                      className="object-cover h-full mx-auto selection:w-full"
                      src={`/products/${product?.image}`}
                      alt="Product Image"
                    />
                  </div>
                  <div className="flex justify-center mb-4 -mx-2">
                    <div className="w-1/2 px-2">
                      <Link
                        href={product?.media_url}
                        bg={theme.blue}
                        display="block"
                        w="100%"
                        p=".3rem 1rem"
                        borderRadius="5px"
                        mt={3}
                        color={theme.light}
                        textAlign="center"
                      >
                        {t("pages.products.media_url")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="px-4 md:flex-1">
                  <h2 className="mb-4 text-2xl font-extrabold text-gray-800 dark:text-white">
                    {product?.name}
                  </h2>

                  {auth.user?.role !== "moderator" && (
                    <Text mb={4} fontWeight="bold" color={theme.error}>
                      <Box as="span" color={theme.blue}>
                        ملاحظة:{" "}
                      </Box>
                      عمولة مقترحة {product?.commission || 70} جنيه
                    </Text>
                  )}

                  <p className="flex items-center mb-4 text-sm text-gray-700 dark:text-gray-300">
                    الكود: {product?.barcode}
                    <CopyToClipboard
                      text={product?.barcode}
                      onCopy={() => {
                        toast({
                          position: "top",
                          description: "تم نسخ كود المنتج",
                        });
                      }}
                    >
                      <Box
                        className="text-blue-500 transition-colors duration-200 cursor-pointer hover:text-blue-700 ms-2"
                        as="span"
                      >
                        اضغط للنسخ
                      </Box>
                    </CopyToClipboard>
                  </p>

                  <div className="flex items-center mb-4">
                    <div className="text-lg text-gray-600 dark:text-gray-300">
                      {auth.user.role === "merchant" ? (
                        <>سعر التكلفة: {product?.purchase_price} EGP</>
                      ) : (
                        auth.user?.role !== "moderator" && (
                          <>سعر البيع: {product?.sale_price} EGP</>
                        )
                      )}
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-4 mb-6">
                    {product?.properties
                      ?.filter((prop) => prop.key)
                      .map((prop, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-3"
                        >
                          {prop.value >= 1 ? (
                            <button
                              onClick={() =>
                                setSelectedList([{ ...prop, quantity: 1 }])
                              }
                              className={`px-5 py-2 rounded-lg font-semibold transition-colors duration-300 ease-in-out transform hover:scale-105 focus:outline-none ${
                                selectedList.some(
                                  (item) => item.key === prop.key
                                )
                                  ? "bg-blue-800 text-white shadow-lg"
                                  : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                              }`}
                            >
                              {prop.key}
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                toast({
                                  position: "top",
                                  description: "هذه الخاصية غير متوفرة الأن",
                                })
                              }
                              className={`px-5 py-2 rounded-lg font-semibold transition-colors duration-300 ease-in-out transform hover:scale-105 focus:outline-none bg-red-200 text-red-800 hover:bg-red-300`}
                            >
                              {prop.key}
                            </button>
                          )}
                        </div>
                      ))}
                  </div>

                  {selectedList.length > 0 && auth.user.role !== "merchant" && (
                    <div className="flex justify-center mt-8">
                      <div className="flex items-center gap-3 p-3 bg-white border border-gray-200 rounded-lg shadow-lg">
                        <button
                          onClick={() =>
                            setSelectedList((prev) =>
                              prev.map((item) =>
                                item.key === selectedList[0].key
                                  ? {
                                      ...item,
                                      quantity: Math.max(1, item.quantity - 1),
                                    }
                                  : item
                              )
                            )
                          }
                          className="px-2 py-1 font-semibold text-white transition-all duration-200 bg-red-500 rounded-lg shadow hover:bg-red-600 focus:outline-none"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          value={selectedList[0].quantity}
                          readOnly
                          className="w-10 m-0 my-0 text-lg font-semibold text-center bg-white border border-gray-300 rounded-lg shadow-inner focus:ring-2 focus:ring-blue-500"
                          style={{ margin: "0px" }}
                        />
                        <button
                          onClick={() =>
                            setSelectedList((prev) =>
                              prev.map((item) =>
                                item.key === selectedList[0].key
                                  ? { ...item, quantity: item.quantity + 1 }
                                  : item
                              )
                            )
                          }
                          className="px-2 py-1 font-semibold text-white transition-all duration-200 bg-green-500 rounded-lg shadow hover:bg-green-600 focus:outline-none"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  )}

                  {selectedList.length > 0 && auth.user.role !== "merchant" && (
                    <div className="flex justify-center mt-6">
                      <Button
                        ms={2}
                        p={2}
                        borderRadius="5px"
                        bg={"#16a34a"}
                        _hover={{ bg: "#16a34a " }}
                        // color={theme.light}
                        onClick={() => {
                          const checkIfItemExists = cart.items.find(
                            (item) =>
                              item.product?._id === product._id &&
                              item.property?._id === selectedList[0]?._id
                          );
                          if (checkIfItemExists)
                            return toast({
                              description: "قبل تم إضافة العنصر للسلة من",
                              status: "error",
                            });
                          dispatch(
                            addToCart({
                              product: {
                                _id: product._id,
                                name: product.name,
                                sale_price: product.sale_price,
                                properties: product.properties,
                              },
                              property: selectedList[0],
                              qty: selectedList[0]?.quantity ?? 1,
                            })
                          );
                          toast({
                            description: "تم اضافة المنتج للسلة بنجاح.",
                          });
                          setSelectedList([]);
                          onCartOpen();
                        }}
                        className="flex items-center justify-center gap-2 px-4 py-2 font-semibold text-white transition-colors duration-300 ease-in-out bg-[#FC6736] rounded-lg shadow-lg hover:bg-green-600"
                      >
                        <FiShoppingCart size={20} />
                        <span>أطلب الأن</span>
                      </Button>
                      <Button
                        ms={2}
                        p={2}
                        borderRadius="5px"
                        bg={"#FC6736"}
                        _hover={{ bg: "#16a34a " }}
                        // color={theme.light}
                        onClick={() => {
                          const checkIfItemExists = cart.items.find(
                            (item) =>
                              item.product?._id === product._id &&
                              item.property?._id === selectedList[0]?._id
                          );
                          if (checkIfItemExists)
                            return toast({
                              description: "قبل تم إضافة العنصر للسلة من",
                              status: "error",
                            });
                          dispatch(
                            addToCart({
                              product: {
                                _id: product._id,
                                name: product.name,
                                sale_price: product.sale_price,
                                properties: product.properties,
                              },
                              property: selectedList[0],
                              qty: selectedList[0]?.quantity ?? 1,
                            })
                          );
                          toast({
                            description: "تم اضافة المنتج للسلة بنجاح.",
                          });
                          setSelectedList([]);
                        }}
                        className="flex items-center justify-center gap-2 px-4 py-2 font-semibold text-white transition-colors duration-300 ease-in-out bg-[#FC6736] rounded-lg shadow-lg hover:bg-green-600"
                      >
                        <FiShoppingCart size={20} />
                        <span>أضف إلى السلة</span>
                      </Button>
                    </div>
                  )}

                  {auth.user.role === "marketer" && (
                    <>
                      {product?.percent > 0 && (
                        <Flex
                          boxShadow={theme.shadow}
                          padding={{ base: 3, xl: 6 }}
                          alignItems="center"
                          justifyContent="center"
                          gap={9}
                          className="text-[#0C2D57] bg-[#EFECEC] rounded-xl shadow-xl hover:shadow-md transition-shadow"
                        >
                          {/* First Circular Progress for Delivery Percentage */}
                          <Box textAlign="center">
                            <CircularProgress
                              value={
                                (product.percent < 60
                                  ? product.percent + 12
                                  : product.percent) ?? 0
                              }
                              color="green.400"
                              size="90px"
                            >
                              <CircularProgressLabel
                                fontSize="md"
                                fontWeight="bold"
                              >
                                {`${
                                  (product.percent < 60
                                    ? product.percent + 12
                                    : product.percent
                                  )?.toFixed(2) ?? 0
                                } %`}
                              </CircularProgressLabel>
                            </CircularProgress>
                            <Text
                              mt={2}
                              fontWeight="bold"
                              fontSize={{ base: 11, xl: 13 }}
                            >
                              نسبة تسليم المنتج
                            </Text>
                          </Box>

                          {/* Second Circular Progress for Success Percentage */}
                          <Box textAlign="center">
                            <CircularProgress
                              value={
                                (product.percent < 80
                                  ? product.percent + 15
                                  : product.percent) ?? 0
                              }
                              color="blue.400"
                              size="90px"
                            >
                              <CircularProgressLabel
                                fontSize="md"
                                fontWeight="bold"
                              >
                                {`${
                                  (product.percent < 80
                                    ? product.percent + 15
                                    : product.percent
                                  )?.toFixed(2) ?? 0
                                } %`}
                              </CircularProgressLabel>
                            </CircularProgress>
                            <Text
                              mt={2}
                              fontWeight="bold"
                              fontSize={{ base: 11, xl: 13 }}
                            >
                              نسبة نجاح المنتج
                            </Text>
                          </Box>
                          <Box textAlign="center">
                            <CircularProgress
                              value={product.myPercent}
                              color="orange.400"
                              size="90px"
                            >
                              <CircularProgressLabel
                                fontSize="md"
                                fontWeight="bold"
                              >
                                {`${product.myPercent?.toFixed(2) ?? 0} %`}
                              </CircularProgressLabel>
                            </CircularProgress>
                            <Text
                              mt={2}
                              fontWeight="bold"
                              fontSize={{ base: 11, xl: 13 }}
                            >
                              نسبة تسليماتي
                            </Text>
                          </Box>
                        </Flex>
                      )}
                    </>
                  )}

                  {(auth.user.role === "admin" ||
                    (auth.user.role === "merchant" &&
                      auth.user._id === product?.merchant?._id)) && (
                    <Flex justifyContent="center" my={5}>
                      <Box
                        className="p-4 text-lg text-center text-white bg-green-700 rounded-lg shadow-md"
                        width="fit-content"
                      >
                        {product?.percent > 0 ? (
                          <>
                            <Box>نسبة تسليم المنتج</Box>
                            {auth.user.role === "admin" &&
                              `${product.percent?.toFixed(2) ?? 0} %`}
                          </>
                        ) : (
                          "لم يتم تسليم اي طلبات حتى الأن"
                        )}
                      </Box>
                    </Flex>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Tabs
            variant="soft-rounded"
            colorScheme="blue"
            onChange={(index) =>
              setView(index === 0 ? "description" : "properties")
            }
          >
            <TabList
              justifyContent={"center"}
              className="flex justify-center w-full gap-4 py-4 mx-auto bg-white rounded-lg shadow-md"
            >
              <Tab>الوصف</Tab>
              <Tab>توزيع الاستوك</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box className="flex justify-center w-full px-4 py-4 my-4 overflow-x-auto bg-white rounded-lg shadow-md">
                  {product?.description && (
                    <div className="p-6 mt-4 bg-white border border-gray-200 rounded-lg shadow-md">
                      {product.description.split(/\n/).map((item, index) => (
                        <p
                          key={index}
                          className="mb-4 text-base leading-relaxed text-center text-gray-800"
                        >
                          {item.trim()}
                        </p>
                      ))}
                    </div>
                  )}
                </Box>
              </TabPanel>

              <TabPanel>
                <Box className="mb-4">
                  <div className="flex items-center mt-4 max-w-[95%] overflow-x-auto mx-auto">
                    <Table variant="striped" colorScheme="teal" size="sm">
                      <Thead>
                        <Tr>
                          <Th>#</Th>
                          <Th>الخاصية</Th>
                          <Th>الكمية</Th>
                          {(auth.user.role === "admin" ||
                            (auth.user.role === "merchant" &&
                              auth.user._id === product?.merchant?._id)) && (
                            <>
                              <Th>معلق</Th>
                              <Th>جار التحضير</Th>
                              <Th>تم الشحن</Th>
                              <Th>تم التاجيل</Th>
                              <Th>طلب العميل الإسترجاع</Th>
                              <Th>طلبات جار الإسترجاع</Th>
                              <Th>مرتجعة بعد التسليم</Th>
                              <Th>تم التسليم</Th>
                            </>
                          )}
                          {auth.user.role === "marketer" && (
                            <Th>كود الخاصية</Th>
                          )}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {product?.properties.map((prop, index) => (
                          <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{prop.key}</Td>
                            <Td>{prop.value}</Td>
                            {(auth.user.role === "admin" ||
                              (auth.user.role === "merchant" &&
                                auth.user._id === product?.merchant?._id)) && (
                              <>
                                <Td>{prop.pendingOrders}</Td>
                                <Td>{prop.preparingOrders}</Td>
                                <Td>{prop.shippedOrders}</Td>
                                <Td>{prop.holdingOrders}</Td>
                                <Td>{prop.asToReturnOrders}</Td>
                                <Td>{prop.skipOrders}</Td>
                                <Td>{prop.returned2Orders}</Td>
                                <Td>{prop.availableOrders}</Td>
                              </>
                            )}
                            {auth.user.role === "marketer" && (
                              <Td>
                                <CopyToClipboard
                                  text={prop._id}
                                  onCopy={() =>
                                    toast({
                                      position: "top",
                                      description: "تم نسخ كود الخاصية",
                                      status: "success",
                                      duration: 2000,
                                      isClosable: true,
                                    })
                                  }
                                >
                                  <button className="text-blue-500 transition-colors duration-200 text-[10px] sm:text-[12px] hover:text-blue-600 focus:outline-none">
                                    اضغط للنسخ
                                  </button>
                                </CopyToClipboard>
                              </Td>
                            )}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </div>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
      <CartDrawer isOpen={isCartOpen} onClose={onCartClose} />
    </div>
  );
};

export default ProductDetails;
