import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Flex,
  Text,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  Select,
  Checkbox,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import Search from "../../../shared/search/Search";

import { createUser } from "../../../../store/users/usersSlice";
import { getRules } from "../../../../store/rules/rulesSlice";

import theme from "../../../global/theme";

const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const users = useSelector((state) => state.users);
  const rules = useSelector((state) => state.rules);
  const dispatch = useDispatch();

  const [rulesPage, setRulePage] = useState(1);
  const [rulesQuery, setRuleQuery] = useState("");

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  useEffect(() => {
    dispatch(getRules({ page: rulesPage, query: rulesQuery }));
  }, [dispatch, rulesPage, rulesQuery]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  watch(["rule", "role"]);

  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values) => {
            values.rule = values.rule?._id;

            dispatch(createUser(values))
              .unwrap()
              .then((_) => {
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            {t("pages.users.create_user")}
          </ModalHeader>
          <ModalBody>
            {users.errors.length > 0 && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                {users.errors?.map((error, index) => (
                  <Text key={index}>{error?.msg}</Text>
                ))}
              </Alert>
            )}

            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.users.name")}
                </FormLabel>
                <Input
                  type="text"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={t("pages.users.name")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("name", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.name?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.name.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.users.email")}
                </FormLabel>
                <Input
                  type="email"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={t("pages.users.email")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("email", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.email?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.email.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.users.phone")}
                </FormLabel>
                <Input
                  type="tel"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={t("pages.users.phone")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("phone", {
                    required: `${t("validation.required")}`,
                    minLength: {
                      value: 11,
                      message: `${t("validation.min_length")} 11`,
                    },
                    maxLength: {
                      value: 11,
                      message: `${t("validation.max_length")} 11`,
                    },
                  })}
                />
                {errors.phone?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.phone.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel color={theme.light} textTransform="capitalize">
                  {t("pages.users.password")}
                </FormLabel>
                <InputGroup>
                  <Input
                    type={isPasswordShown ? "text" : "password"}
                    placeholder={t("pages.users.password")}
                    border="none"
                    color={theme.dark}
                    bg={theme.bg}
                    _placeholder={{ color: theme.text }}
                    paddingInline={4}
                    {...register("password", {
                      required: `${t("validation.required")}`,
                      minLength: {
                        value: 8,
                        message: `${t("validation.min_length")} 8`,
                      },
                    })}
                  />
                  <InputRightElement>
                    <Button
                      p={0}
                      bg="none"
                      color={theme.dark}
                      _hover={{ bg: "none" }}
                      type="button"
                      onClick={() => setIsPasswordShown(!isPasswordShown)}
                    >
                      {isPasswordShown ? (
                        <FiIcons.FiEyeOff />
                      ) : (
                        <FiIcons.FiEye />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password?.message && (
                  <Text color={theme.error}>{errors.password?.message}</Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  نوع الحساب
                </FormLabel>
                <Select
                  border="none"
                  color={theme.dark}
                  bg={theme.bg}
                  {...register("role", {
                    required: `${t("validation.required")}`,
                  })}
                >
                  <option style={{ color: theme.dark }} value="merchant">
                    تاجر
                  </option>
                  <option style={{ color: theme.dark }} value="marketer">
                    مسوق
                  </option>
                  <option style={{ color: theme.dark }} value="admin">
                    ادمن
                  </option>
                </Select>
                {errors.role?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.role.message}
                  </Text>
                )}
              </FormControl>

              {getValues("role") && getValues("role") === "admin" && (
                <FormControl>
                  <FormLabel
                    fontWeight="bold"
                    textTransform="capitalize"
                    color="white"
                  >
                    {t("pages.users.rule")}
                  </FormLabel>

                  <Search
                    page={rulesPage}
                    itemsCount={rules.data?.itemsCount}
                    data={rules.data?.data}
                    prop="name"
                    currentElement={getValues("rule")}
                    handleChangePage={(page) => {
                      setRulePage(page);
                    }}
                    handleChangeQuery={(query) => {
                      setRuleQuery(query);
                    }}
                    handleSelected={(rule) => {
                      setValue("rule", rule);
                    }}
                  />
                </FormControl>
              )}
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.users.status")}
                </FormLabel>

                <Checkbox color={theme.light} {...register("is_active")}>
                  {t("pages.users.is_active")}
                </Checkbox>
              </FormControl>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                isLoading={users.isLoading}
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={users.isLoading}
                _hover={{ background: "red.600" }}
                onClick={onClose}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreateModal;
