import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Box,
  Button,
  Text,
  SimpleGrid,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";

import {
  FiDollarSign,
  FiClock,
  FiPackage,
  FiCheckCircle,
} from "react-icons/fi";

import InputSearch from "../../shared/inputSearch/InputSearch";
import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import RequestsTable from "./RequestsTable";
import CreateModal from "./actions/CreateModal";
import UpdateModal from "./actions/UpdateModal";
import Filter from "./actions/Filter";

import { getRequests } from "../../../store/requests/requestsSlice";
import { getAccounts } from "../../../store/accounts/accountsSlice";

import { RequestsWrapper } from "./RequestsStyle";

import theme from "../../global/theme";

const Requests = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const accounts = useSelector((state) => state.accounts);
  const requests = useSelector((state) => state.requests);

  const [showCreateRequest, setShowCreateRequest] = useState(false);
  const [showUpdateRequest, setShowUpdateRequest] = useState(false);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const defaultFilter = {};
  const [filter, setFilter] = useState(JSON.stringify(defaultFilter));

  const [accountsFilter] = useState(JSON.stringify({}));
  useEffect(() => {
    dispatch(getAccounts({ page: 1, query: "", filter: accountsFilter }));
  }, [dispatch, filter, accountsFilter]);

  useEffect(() => {
    dispatch(getRequests({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <RequestsWrapper>
      <Breadcrumbs
        currentPage={t("pages.requests.requests")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      <Alert bg={theme.primary} color={theme.light} mb={4} fontSize={22}>
        يتم مراجعة طلب السحب خلال 24 ساعه والحد الأدنى للسحب 100 جنية.
      </Alert>

      {requests.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {requests.errors.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      {accounts.data.data &&
        [accounts.data?.data[0]].map((el) => (
          <SimpleGrid
            columns={{ base: 2, lg: 3, xl: 4 }}
            spacing={6}
            key={el.id}
          >
            <Flex
              boxShadow="lg"
              bg="#FFB0B0"
              p={6}
              rounded="lg"
              alignItems="center"
              justifyContent="space-between"
              _hover={{
                boxShadow: "xl",
                transform: "scale(1.02)",
                transition: "0.2s ease-in-out",
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                bg="white"
                rounded="full"
                p={3}
                boxSize="60px"
                color="#0C2D57"
              >
                <FiClock size={32} />
              </Flex>
              <Box textAlign="right">
                <Text fontSize="2xl" fontWeight="bold" color="#0C2D57">
                  {el?.pending}
                </Text>
                <Text fontSize="sm" fontWeight="medium" color="#0C2D57">
                  رصيد معلق
                </Text>
              </Box>
            </Flex>

            {/* Preparing Balance */}
            <Flex
              boxShadow="lg"
              bg="#EFECEC"
              p={6}
              rounded="lg"
              alignItems="center"
              justifyContent="space-between"
              _hover={{
                boxShadow: "xl",
                transform: "scale(1.02)",
                transition: "0.2s ease-in-out",
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                bg="white"
                rounded="full"
                p={3}
                boxSize="60px"
                color="#FC6736"
              >
                <FiPackage size={32} />
              </Flex>
              <Box textAlign="right">
                <Text fontSize="2xl" fontWeight="bold" color="#FC6736">
                  {el?.preparing}
                </Text>
                <Text fontSize="sm" fontWeight="medium" color="#FC6736">
                  رصيد جار التحضير
                </Text>
              </Box>
            </Flex>

            {/* Shipped Balance */}
            <Flex
              boxShadow="lg"
              bg="#0C2D57"
              p={6}
              rounded="lg"
              alignItems="center"
              justifyContent="space-between"
              _hover={{
                boxShadow: "xl",
                transform: "scale(1.02)",
                transition: "0.2s ease-in-out",
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                bg="white"
                rounded="full"
                p={3}
                boxSize="60px"
                color="#FC6736"
              >
                <FiPackage size={40} />
              </Flex>
              <Box textAlign="right">
                <Text fontSize="2xl" fontWeight="bold" color="#FC6736">
                  {el?.shipped}
                </Text>
                <Text fontSize="sm" fontWeight="medium" color="#FC6736">
                  رصيد قيد الشحن
                </Text>
              </Box>
            </Flex>

            {/* Available Balance */}
            <Flex
              boxShadow="lg"
              bg="#FC6736"
              p={6}
              rounded="lg"
              alignItems="center"
              justifyContent="space-between"
              _hover={{
                boxShadow: "xl",
                transform: "scale(1.02)",
                transition: "0.2s ease-in-out",
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                bg="white"
                rounded="full"
                p={3}
                boxSize="60px"
                color="#0C2D57"
              >
                <FiCheckCircle size={32} />
              </Flex>
              <Box textAlign="right">
                <Text fontSize="2xl" fontWeight="bold" color="#0C2D57">
                  {el?.available}
                </Text>
                <Text fontSize="sm" fontWeight="medium" color="#0C2D57">
                  رصيد متاح
                </Text>
              </Box>
            </Flex>
          </SimpleGrid>
        ))}

      <SimpleGrid
        mt={8}
        columns={{ sm: 1, md: 2 }}
        justifyContent="space-between"
      >
        <Flex mb={8}>
          {auth.user.role !== "admin" && (
            <Button
              bg={theme.blue}
              textTransform="uppercase"
              fontWeight="bold"
              fontSize={18}
              marginInlineEnd={4}
              color="white"
              _hover={{ bg: theme.blue }}
              onClick={() => setShowCreateRequest(true)}
            >
              طلب سحب
            </Button>
          )}
        </Flex>

        <Flex marginBottom={8} justifyContent="flex-end">
          <InputSearch
            isLoading={accounts.isLoading}
            handleSearch={(query) => setQuery(query)}
          />

          <Filter
            handleFilter={(values) =>
              setFilter(JSON.stringify({ ...defaultFilter, ...values }))
            }
            handleClearFilter={() => setFilter(JSON.stringify(defaultFilter))}
            disablesBtns={requests.isLoading}
          />
        </Flex>
      </SimpleGrid>

      {requests.data?.itemsCount > 0 ? (
        <RequestsTable
          data={requests.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdateRequest(el)}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.requests.no_requests")}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={requests.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreateRequest && (
        <CreateModal onClose={() => setShowCreateRequest(false)} />
      )}
      {showUpdateRequest && (
        <UpdateModal
          data={showUpdateRequest}
          onClose={() => setShowUpdateRequest(false)}
        />
      )}
    </RequestsWrapper>
  );
};

export default Requests;
