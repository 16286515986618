import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let initialState = {
  isLoading: false,
  data: {},
  errors: [],
};

// Async thunk to get accounts
export const getAccounts = createAsyncThunk(
  "accounts/getAccounts",
  async (args, thunkApi) => {
    try {
      const {
        page,
        size = 10,
        query,
        filter = JSON.stringify({}),
        sort = "",
      } = args;
      const { data } = await axios.get(
        `/api/get_accounts?page=${page}&size=${size}&query=${query}&filter=${filter}&sort=${sort}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

// Async thunk to update an account
export const updateAccount = createAsyncThunk(
  "accounts/updateAccount",
  async (args, thunkApi) => {
    try {
      const {
        pending,
        preparing,
        shipped,
        available,
        availableToWithdraw,
        id,
      } = args;
      const { data } = await axios.patch(
        `/api/accounts/${id}`,
        { pending, preparing, shipped, available, availableToWithdraw },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle getAccounts thunk
    builder
      .addCase(getAccounts.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getAccounts.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors;
      })
      // Handle updateAccount thunk
      .addCase(updateAccount.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateAccount.fulfilled, (state) => {
        state.isLoading = false;
        state.errors = [];
      })
      .addCase(updateAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      });
  },
});

export default accountsSlice.reducer;
