import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as FiIcons from "react-icons/fi";
import {
  Flex,
  Text,
  Alert,
  AlertIcon,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";

import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import MerchantsAccountsTable from "./MerchantsAccountsTable";
import Filter from "./actions/Filter";

import { getAccounts } from "../../../store/accounts/accountsSlice";

import { MerchantsAccountsWrapper } from "./MerchantsAccountsStyle";
import { FiClock, FiSettings, FiTruck, FiCheckCircle } from "react-icons/fi";
import theme from "../../global/theme";
import UpdateAccount from "../marketersAccounts/actions/UpdateAccount";

const MerchantsAccounts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.accounts);
  const [showUpdateAccount, setShowUpdateAccount] = useState(false);
  const [account, setAccount] = useState(null);
  const [page, setPage] = useState(1);
  const defaultFilter = { role: "merchant" };
  const [filter, setFilter] = useState(JSON.stringify(defaultFilter));
  useEffect(() => {
    if (!showUpdateAccount) {
      setAccount(null);
    }
  }, [showUpdateAccount]);
  useEffect(() => {
    dispatch(getAccounts({ page, query: "", filter }));
  }, [dispatch, page, filter]);

  return (
    <MerchantsAccountsWrapper>
      <Breadcrumbs
        currentPage={t("pages.accounts.accounts")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {accounts.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {accounts.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={6} mb={8}>
        {/* Pending Balance */}
        <Flex
          boxShadow="lg"
          bg="yellow.400"
          p={6}
          align="center"
          justify="space-between"
          rounded="lg"
        >
          <Box color="yellow.900">
            <FiClock size={50} />
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize="2xl" color="white" mt={2}>
              {accounts.data?.pendingBalance || "-"}
            </Text>
            <Text
              fontWeight="medium"
              fontSize="sm"
              textTransform="capitalize"
              color="white"
            >
              ارصدة معلقه
            </Text>
          </Box>
        </Flex>

        {/* Preparing Balance */}
        <Flex
          boxShadow="lg"
          bg="blue.500"
          p={6}
          align="center"
          justify="space-between"
          rounded="lg"
        >
          <Box color="blue.900">
            <FiSettings size={50} />
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize="2xl" color="white" mt={2}>
              {accounts.data?.preparingBalance || "-"}
            </Text>
            <Text
              fontWeight="medium"
              fontSize="sm"
              textTransform="capitalize"
              color="white"
            >
              ارصدة جار التحضير
            </Text>
          </Box>
        </Flex>

        {/* Shipped Balance */}
        <Flex
          boxShadow="lg"
          bg="red.500"
          p={6}
          align="center"
          justify="space-between"
          rounded="lg"
        >
          <Box color="red.900">
            <FiTruck size={50} />
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize="2xl" color="white" mt={2}>
              {accounts.data?.shippedBalance || "-"}
            </Text>
            <Text
              fontWeight="medium"
              fontSize="sm"
              textTransform="capitalize"
              color="white"
            >
              ارصدة تم الشحن
            </Text>
          </Box>
        </Flex>

        {/* Available Balance */}
        <Flex
          boxShadow="lg"
          bg="green.500"
          p={6}
          align="center"
          justify="space-between"
          rounded="lg"
        >
          <Box color="green.900">
            <FiCheckCircle size={50} />
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize="2xl" color="white" mt={2}>
              {accounts.data?.availableBalance || "-"}
            </Text>
            <Text
              fontWeight="medium"
              fontSize="sm"
              textTransform="capitalize"
              color="white"
            >
              ارصدة متاحه
            </Text>
          </Box>
        </Flex>
      </SimpleGrid>

      <Filter
        handleFilter={(values) =>
          setFilter(JSON.stringify({ ...values, ...defaultFilter }))
        }
        handleClearFilter={() => setFilter(JSON.stringify(defaultFilter))}
        disablesBtns={accounts.isLoading}
      />

      {accounts.data?.itemsCount > 0 ? (
        <MerchantsAccountsTable
          data={accounts.data.data}
          page={page}
          setShowUpdateAccount={setShowUpdateAccount}
          setAccount={setAccount}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.accounts.no_accounts")}
          </Text>
        </Flex>
      )}
      {showUpdateAccount && (
        <UpdateAccount
          data={account}
          onClose={() => {
            setShowUpdateAccount(false);
            setAccount(null);
            dispatch(getAccounts({ page, query: "", filter }));
          }}
        />
      )}
      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={accounts.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>
    </MerchantsAccountsWrapper>
  );
};

export default MerchantsAccounts;
