import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Box, Button, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";

// import Table from "../../shared/table/Table";

import theme from "../../global/theme";

const RequestsTable = ({ data, page, handleUpdate }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  return (
    <Box overflowX="auto">
      <Table variant={"striped"} colorScheme="teal">
        <Thead>
          <Tr>
            <Th>#</Th>
            {auth.user.role === "admin" ? (
              <>
                <Th>الاسم</Th>
                <Th>النوع</Th>
                <Th>الكود</Th>
                <Th>رقم الهاتف</Th>
              </>
            ) : (
              <Th>رقم الهاتف</Th>
            )}
            <Th>{t("pages.requests.created_at")}</Th>
            <Th>اخر تحديث</Th>
            <Th>{t("pages.requests.amount")}</Th>
            <Th>{t("pages.requests.payment_method")}</Th>
            <Th>{t("pages.requests.note")}</Th>
            <Th>{t("pages.requests.status")}</Th>
            <Th>السبب</Th>
            {auth.user.role === "admin" && <Th>{t("general.action")}</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((el, index) => (
            <Tr key={el._id}>
              <Td>{(page - 1) * 10 + (index + 1)}</Td>
              {auth.user.role === "admin" ? (
                <>
                  <Td>{el.target?.name}</Td>
                  <Td>{el.target?.role}</Td>
                  <Td>{el.target?.code}</Td>
                  <Td>{el.phone}</Td>
                </>
              ) : (
                <Td>{el.phone}</Td>
              )}
              <Td>{new Date(el.created_at).toLocaleString()}</Td>
              <Td>
                {el.status === "pending"
                  ? "-"
                  : new Date(el.updated_at).toLocaleString()}
              </Td>
              <Td>{el.amount}</Td>
              <Td>{el.payment_method}</Td>
              <Td>{el.note}</Td>
              <Td>
                <Box
                  style={{
                    backgroundColor:
                      el.status === "pending"
                        ? "orange"
                        : el.status === "rejected"
                        ? theme.error
                        : "green",
                  }}
                  color={theme.light}
                  display="inline-block"
                  paddingInline={2}
                  paddingBlock={1}
                  borderRadius="5px"
                >
                  {t(`pages.requests.${el.status}`)}
                </Box>
              </Td>
              <Td>{el.reason}</Td>
              {auth.user.role === "admin" && (
                <Td>
                  {el.status === "pending" && auth.user.role === "admin" ? (
                    <Button
                      bg="Transparent"
                      color="green"
                      size="xs"
                      onClick={() => handleUpdate(el)}
                    >
                      <FiIcons.FiEdit size={20} />
                    </Button>
                  ) : (
                    "-"
                  )}
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default RequestsTable;
