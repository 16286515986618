import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Flex,
  Text,
  Alert,
  AlertIcon,
  Checkbox,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import { updateUser } from "../../../../store/users/usersSlice";

import theme from "../../../global/theme";

const Update = ({ data, onClose }) => {
  const { t } = useTranslation();
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });

  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values) => {
            console.log(values);
            dispatch(updateUser(values))
              .unwrap()
              .then((_) => {
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            {t("general.update")}
          </ModalHeader>
          <ModalBody>
            {users.errors.length > 0 && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                {users.errors?.map((error, index) => (
                  <Text key={index}>{error?.msg}</Text>
                ))}
              </Alert>
            )}

            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.users.name")}
                </FormLabel>
                <Input
                  type="text"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={t("pages.users.name")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("name", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.name?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.name.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.users.email")}
                </FormLabel>
                <Input
                  type="email"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={t("pages.users.email")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("email", {
                    required: `${t("validation.required")}`,
                  })}
                />
                {errors.email?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.email.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.users.phone")}
                </FormLabel>
                <Input
                  type="tel"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder={t("pages.users.phone")}
                  _placeholder={{ textTransform: "capitalize" }}
                  {...register("phone", {
                    required: `${t("validation.required")}`,
                    minLength: {
                      value: 11,
                      message: `${t("validation.min_length")} 11`,
                    },
                    maxLength: {
                      value: 11,
                      message: `${t("validation.max_length")} 11`,
                    },
                  })}
                />
                {errors.phone?.message && (
                  <Text color="red.600" marginTop={2}>
                    {errors.phone.message}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color="white"
                >
                  {t("pages.users.status")}
                </FormLabel>

                <Checkbox color={theme.light} {...register("is_active")}>
                  {t("pages.users.is_active")}
                </Checkbox>
              </FormControl>

              {data.role === "marketer" && (
                <FormControl>
                  <FormLabel
                    fontWeight="bold"
                    textTransform="capitalize"
                    color="white"
                  >
                    تفعيل رفع اكسل
                  </FormLabel>

                  <Checkbox
                    color={theme.light}
                    {...register("allow_upload_excel")}
                  >
                    تفعيل رفع اكسل
                  </Checkbox>
                </FormControl>
              )}
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                isLoading={users.isLoading}
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={users.isLoading}
                _hover={{ background: "red.600" }}
                onClick={onClose}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default Update;
