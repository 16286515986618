import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";

import Table from "../../shared/table/Table";
import { FiEdit, FiMail, FiMessageSquare } from "react-icons/fi";
const UsersTable = ({ data, page, handleUpdate, handleOpen }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const handleWhatsApp = (phone) => {
    const url = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
      "2" + phone
    )}`;
    window.open(url, "_blank");
  };
  const handleEmail = (email) => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <Table id="table-to-xls_users">
      <thead>
        <tr>
          <th>#</th>
          <th>{t("pages.users.name")}</th>
          <th>{t("pages.users.email")}</th>
          <th>{t("pages.users.phone")}</th>
          <th>{t("pages.users.code")}</th>
          <th>نوع الحساب</th>
          <th>{t("pages.users.is_active")}</th>
          <th>{t("general.action")}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{(page - 1) * 10 + (index + 1)}</td>
            <td>{el.name}</td>
            <td>
              {el.email}
              <Button
                bg="transparent"
                color="blue.500"
                size="xs"
                ml={2}
                onClick={() => handleEmail(el.email)}
              >
                <FiMail size={16} />
              </Button>
            </td>
            <td>
              {el.phone}
              <Button
                bg="transparent"
                color="blue.500"
                size="xs"
                ml={2}
                onClick={() => handleWhatsApp(el.phone)}
              >
                <FiMessageSquare size={16} />
              </Button>
            </td>
            <td>{el.code}</td>
            <td>{el.role}</td>
            <td>{el.is_active ? t("general.yes") : t("general.no")}</td>
            <td>
              <Button
                bg="transparent"
                color="green.500"
                size="xs"
                onClick={() => handleUpdate(el)}
              >
                <FiEdit size={20} />
              </Button>
              {auth.user._id !== el._id && (
                <Button
                  bg="green.600"
                  color="white"
                  size="xs"
                  onClick={() => handleOpen(el)}
                >
                  فتح الحساب
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default UsersTable;
