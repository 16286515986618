import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  SimpleGrid,
  Flex,
  Text,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { FiSave, FiMinimize2 } from "react-icons/fi";
import { createRequest } from "../../../../store/requests/requestsSlice";

const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const requests = useSelector((state) => state.requests);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = (values) => {
    dispatch(createRequest(values))
      .unwrap()
      .then(() => {
        const audio = new Audio("/atm.mpeg");
        audio.play();
        onClose();
      });
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius="lg"
        py={4}
        px={6}
        bg="gray.800"
        color="white"
        shadow="xl"
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader
            textAlign="center"
            fontWeight="bold"
            fontSize="2xl"
            textTransform="uppercase"
            color="orange.400"
          >
            {t("pages.requests.create_request")}
          </ModalHeader>
          <ModalBody>
            {requests.errors.length > 0 && (
              <Alert status="error" borderRadius="md" mb={4}>
                <AlertIcon />
                <Flex direction="column">
                  {requests.errors.map((error, index) => (
                    <Text key={index} fontSize="sm">
                      {error?.msg}
                    </Text>
                  ))}
                </Flex>
              </Alert>
            )}

            <SimpleGrid columns={{ sm: 1 }} spacing={4}>
              <FormControl isInvalid={errors.phone}>
                <FormLabel>{t("pages.requests.phone")}</FormLabel>
                <Input
                  type="tel"
                  placeholder={t("pages.requests.phone")}
                  {...register("phone", {
                    required: t("validation.required"),
                    minLength: {
                      value: 11,
                      message: `${t("validation.min_length")} 11`,
                    },
                    maxLength: {
                      value: 11,
                      message: `${t("validation.max_length")} 11`,
                    },
                  })}
                />
                <Text fontSize="sm" color="red.500">
                  {errors.phone?.message}
                </Text>
              </FormControl>

              <FormControl isInvalid={errors.payment_method}>
                <FormLabel>{t("pages.requests.payment_method")}</FormLabel>
                <Input
                  type="text"
                  placeholder={t("pages.requests.payment_method")}
                  {...register("payment_method", {
                    required: t("validation.required"),
                  })}
                />
                <Text fontSize="sm" color="red.500">
                  {errors.payment_method?.message}
                </Text>
              </FormControl>

              <FormControl isInvalid={errors.amount}>
                <FormLabel>{t("pages.requests.amount")}</FormLabel>
                <Input
                  type="number"
                  placeholder={t("pages.requests.amount")}
                  {...register("amount", {
                    required: t("validation.required"),
                    min: {
                      value: 1,
                      message: `${t("validation.min")} 1`,
                    },
                  })}
                />
                <Text fontSize="sm" color="red.500">
                  {errors.amount?.message}
                </Text>
              </FormControl>

              <FormControl>
                <FormLabel>{t("pages.requests.note")}</FormLabel>
                <Textarea
                  placeholder={t("pages.requests.note")}
                  {...register("note", {
                    maxLength: {
                      value: 150,
                      message: `${t("validation.max_length")} 150`,
                    },
                  })}
                />
                <Text fontSize="sm" color="red.500">
                  {errors.note?.message}
                </Text>
              </FormControl>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Flex justify="flex-end" gap={4}>
              <Button
                type="submit"
                colorScheme="green"
                size="md"
                rightIcon={<FiSave />}
                isLoading={requests.isLoading}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                colorScheme="red"
                size="md"
                rightIcon={<FiMinimize2 />}
                onClick={onClose}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreateModal;
